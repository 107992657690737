<template>
  <el-dialog
    title="添加访视记录"
    :visible.sync="state"
    :before-close="handleClose"
  >
    <el-form :model="ruleForm" :rule="rules" ref="ruleForm" v-if="state">
      <div class="top">
      <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="input3"
            class="input-with-select"
            style="width:240px"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="form.sex" placeholder="请选择">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="诊断查询">
          <el-select v-model="form.query" placeholder="请选择">
            <el-option label="诊断1" value="healthy"></el-option>
            <el-option label="诊断2" value="illness"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="middle">
        
        <el-form-item label="年龄区间">
          <el-date-picker
            v-model="form.date1"
            type="datetime"
            style="width: 114px;"
          >
          </el-date-picker>
          <el-date-picker
            v-model="form.date2"
            type="datetime"
            style="width: 114px;"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">搜索</el-button>
          <el-button type="reset">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
        ref="multipleTable"
        :data="tabData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="姓名"  prop="patience_name"> </el-table-column>
        <el-table-column prop="patience_sex" label="性别"> </el-table-column>
        <el-table-column prop="age" label="年龄" > </el-table-column>
        <el-table-column prop="patience_link_tel" label="联系方式" >
        </el-table-column>
        <el-table-column prop="diagnose" label="诊断" >
        </el-table-column>
        <el-table-column prop="visitInfoTime" label="最近访视日期" width="120">
        </el-table-column>
        <el-table-column prop="payload" label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getVisitApi, postVisitInfoAddApi } from "@/api/visit";
export default {
  props: {
    state: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    // this.getQuestionList();
    // this.handleInitData()
    if (localStorage.userInfo) {
      let userInfo = JSON.parse(localStorage.userInfo);
      this.userId = userInfo.id;
      this.unionId = userInfo.doctorUnionId;
    }
    this.getDataList();
  },
  methods: {
    async handleInitData() {
      const list = await getVisitApi();
      console.log("visit", list.data);
    },
    async handleClose() {
      const res = await postVisitInfoAddApi();
      this.$emit("update:state", false);
    },
    getDataList(d, n) {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let userInfo = {};
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
      }
      let type = "";
      let typeSec = "";
      let tableBelongs = "";
      if (d) {
        if (d.isEdit == false) {
          tableBelongs = 1;
        }
        if (d.type == "表单类别") {
          type = d.value;
          typeSec = "";
          this.contentType = d.value;
          this.contentTypeSec = "";
        } else {
          type = n.parent.data.value;
          typeSec = d.value;
          this.contentType = n.parent.data.value;
          this.contentTypeSec = d.value;
        }
      }
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/vfTableInfo/list"),
        method: "post",
        data: {
          // hospitalId: userInfo.deptPid,
          intParam1: this.pageSize,
          intParam2: this.pageIndex,
          stringParam1: this.questionName,
          intParam6: this.isPublish,
          intParam7: this.isUse,
          intParam3: 1,
          intParam5: typeSec,
          intParam4: type,
          stringParam3: teamsId
        }
      }).then(({ data }) => {
        if (data.status) {
          this.dataList = data.data;
          this.totalPage = data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    }
  },
  data() {
    return {
      radio: 7,
      // 日期
      
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      form: {
          patience_name:"this.patience_name",
          sex: "",
          query: "",
          data1: "",
          data2: ""
        },
      rules: {
        visitTime: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change"
          }
        ],
        visitType: [
          { required: true, message: "请选择访视类型", trigger: "change" }
        ]
      },
      // tree
      defaultProps: {
        children: "children",
        label: "label"
      },
      formLabelWidth: "120px"
    };
  }
};
</script>
<style lang="scss" scoped>
.el-form {
  .top{
    display: flex;
  }
  .middle{
    display: flex;
  }
      .el-form-item {
        display: flex;
        margin-right: 48px;
        white-space: nowrap;
        .el-form-item__content {
          width: 240px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
        }
      }
    }
    .button {
      float: left;
      padding: 24px 0;
      .el-button {
        padding: 8px 23px;
        height: 36px;
        background: #0099ff;
        border-radius: 2px;
      }
    }</style>
